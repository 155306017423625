import {FunctionComponent, useState, useEffect} from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Settings } from "@mui/icons-material";
import Areas from "./Areas";
import Cameras from "./Cameras";
import Config from "./Config";
import Dashboards from "./Dashboards";
import Fixtures from "./Fixtures";
import HowItWorks from "./HowItWorks";
import Hubs from "./Hubs";
import Integrations from "./Integrations";
import Profile from "./Profile";
import Scans from "./Scans";
import SiteCards from "./SiteCards";
import Sites from "./Sites";
import Video from "./Video";
import Viewer from "./Viewer";
import ErrorPage from "./ErrorPage";
import Console from "./Console";
import Accounts from "./Accounts";
import AccountsList from "./AccountsList";
import AccountInfo from "./AccountInfo";
import AccountCards from "./AccountCards";
import SitesList from "./SitesList";
import SiteInfo from "./SiteInfo";
import SitePage from "./SitePage";
import Tracking from "./Tracking";
import Management from "./management/Management";
import ProvisionedHubs from "./management/ProvisionedHubs";
import AllUsers from "./management/AllUsers";
import Developer from "./debug/Developer";
import Debug from "./debug/Debug";
import ManageVideo from "./management/ManageVideo";
import AccountUsers from "./AccountUsers";
import SiteUsers from "./SiteUsers";
import Invite from "./Invite";
import AccountPage from "./AccountPage";
import { AuthenticationResult} from '@azure/msal-browser';
import Welcome from "./Welcome";
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import { useMsal } from '@azure/msal-react';
import Dashboard from "./Dashboard";
import Heatmap from "./Heatmap";
import Editor from "./Editor";
import WithTitle from "../components/WithTitle";
import Hardware from "./Hardware";
import { useIsAuthenticated } from '@azure/msal-react';
import { observer } from "mobx-react"
import { Typography, Button } from "@mui/material";
import { AccountInfo as MsalAccountInfo } from "@azure/msal-common";
import UserInfo from '../models/UserInfo';
import Interstitial from "./Interstitial";
import { TermsOfServiceData } from "./ToS";
import ProtectedElement from "../components/ProtectedElement";

const App : FunctionComponent = observer(() => {    
    
    const siteBionicsApplication = useSiteBionicsApplication(); 
    
    const isMsalAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [loading, setLoading] = useState(true);
        
    const [interstialData, setInterstialData] = useState<{type: string, user: null | UserInfo, isSystemAdministrator: boolean}>({type: "none", user: null, isSystemAdministrator: false });

    const userInfoFromMsal = (msalAccountInfo: MsalAccountInfo) => {
        let first = "";
        let last = "";
        if (msalAccountInfo.name) {
            const nameParts = msalAccountInfo.name?.split(' ');
            if (nameParts.length === 1) {
                last = nameParts[0];
            } else {
                first = nameParts[0];
                last = nameParts.slice(1).join(' ');
            }
        }
        
        return new UserInfo(msalAccountInfo.localAccountId, msalAccountInfo.username, first, last, TermsOfServiceData.version, new Date());
    }

    const acceptTerms = (firstName: string, lastName: string) => {

        // Update the user data
        
        // For a new user we get an updated first/last name
        if (interstialData.type === "new") {
            interstialData.user!.firstName = firstName;
            interstialData.user!.lastName = lastName;
        }

        // We've seen the latest TOS
        interstialData.user!.tosVersion = TermsOfServiceData.version;
        interstialData.user!.tosDate = new Date();
        
        // Update the user data on the backend
        setLoading(true);
        const result = siteBionicsApplication.service.upsertUserInfo(interstialData.user!).then((result: boolean) => {

            setLoading(false);

            if (!result) return;

            siteBionicsApplication.setMeExtended(interstialData.user!, interstialData.isSystemAdministrator);
            
            setInterstialData({type: "none", user: null, isSystemAdministrator: false});
        });
        

    }
    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {

        const process = async () => {
            if (isMsalAuthenticated) {

                //await sleep(6000);

                // Tell the application that we are using msal :-)
                siteBionicsApplication.service.coreAuthType = "msal";

                // See if the user exists
                const accountId = accounts[0].localAccountId;                
                const isSystemAdministrator = accounts[0].idTokenClaims?.roles?.includes('SiteBionics.SystemAdministrator') ?? false;
                const userInfo = await siteBionicsApplication.service.fetchUserInfo(accountId);                                        

                // If no user we need to create one
                if (!userInfo) {
                    setInterstialData({type: "new", user: userInfoFromMsal(accounts[0]), isSystemAdministrator: isSystemAdministrator })                    
                } else if ((userInfo.tosVersion ?? 0) < TermsOfServiceData.version) {                    
                    setInterstialData({type: "tos", user: userInfoFromMsal(accounts[0]), isSystemAdministrator: isSystemAdministrator });
                } else {
                    siteBionicsApplication.setMeExtended(userInfo, isSystemAdministrator);
                }
                
                setLoading(false);
            } else {
                setLoading(false);
            }


        };

        process();
        
    }, [isMsalAuthenticated]);
    
    
    
    
    // While loading information we display nothing    
    if (loading) {
        return (<></>);
    }

    // If we need to display the interstitial screens...
    if (interstialData.type !== "none") {        
        const interstialRouter: any = createBrowserRouter([
            {
                path: "/", element: <Console modal={true}/>, errorElement: <WithTitle title="Error" children={<ErrorPage/>} />, children: [
                    { index: true, element: <WithTitle title="Welcome" children={<Interstitial firstName={interstialData.user!.firstName} lastName={interstialData.user!.lastName} onAccept={acceptTerms} newUser={interstialData.type === "new"}/>} /> },
                    { path: "*", element: <WithTitle title="Welcome" children={<Interstitial firstName={interstialData.user!.firstName} lastName={interstialData.user!.lastName} onAccept={acceptTerms} newUser={interstialData.type === "new"}/>} /> }
                ]
            }
        ])
    
        return (<>
            <RouterProvider router={interstialRouter!} />
        </>);
    }

    if (siteBionicsApplication.me === undefined) {
        const unAuthRouter: any = createBrowserRouter([
            { 
              path: "/", element: <Console/>, errorElement: <WithTitle title="Error" children={<ErrorPage />} />, children: [
                { index: true, element: <WithTitle title="Welcome" children={<Welcome />} /> },
                { path: "invite", element: <WithTitle title="Invite" children={<Invite />} /> },
                { path: "*", element: <WithTitle title="Welcome" children={<Welcome />} /> },
              ]
            },
        ]);
        return (<>
            <RouterProvider router={unAuthRouter!} />
        </>);
    }

    const authRouter = createBrowserRouter([
        { 
            path: "/", element: <Console />, errorElement: <WithTitle title="Error" children={<ErrorPage />} />, children: [
            { index: true, element: <WithTitle title="Account List" children={<AccountsList />} /> },
            { path: "management", element: <Management />, children: [
                { index: true, element: <ProtectedElement title="Provisioned Hubs" element={<ProvisionedHubs />} sysAdmin={true} /> },
                { path: "provisionedhubs", element: <ProtectedElement title="Provisioned Hubs" element={<ProvisionedHubs />} sysAdmin={true} /> },
                { path: "users", element: <ProtectedElement title="All Users" element={<AllUsers />} sysAdmin={true} /> },
                ]
            },
            { path: "debug", element: <Debug />, children: [
                { path: "developer", element: <ProtectedElement title="Developer" element={<Developer />} sysAdmin={true} /> },
                ]
            },
            { path: "profile", element: <WithTitle title="Profile" children={<Profile />} /> },
            { path: "settings", element: <WithTitle title="Settings" children={<Settings />} /> },
            { path: "howitworks", element: <WithTitle title="How It Works" children={<HowItWorks />} /> },
            { path: "invite", element: <WithTitle title="Invite" children={<Invite />} /> },
            { path: "accounts", element: <Accounts />, children: [
                { index: true, element: <WithTitle title="Account List" children={<AccountsList />} /> },
                { path: ":accountId", element: <AccountPage />, children: [
                    { index: true, element: <ProtectedElement title="Account Cards" element={<AccountCards />} accountCapability="ViewAccount" /> },
                    { path: "info", element: <ProtectedElement title="Account Info" element={<AccountInfo />} accountCapability="ViewAccount"/> },
                    { path: "users", element: <ProtectedElement title="Account Users" element={<AccountUsers />} accountCapability="ManageAccountUsers" /> },
                    { path: "sites", element: <Sites />, children: [
                        { index: true, element: <ProtectedElement title="Site List" element={<SitesList />} accountCapability="ViewAccount" /> },
                        { path: ":siteId", element: <SitePage />, children: [
                            { index: true, element: <ProtectedElement title="Site Cards" element={<SiteCards />} siteCapability="ViewSite" /> },
                            { path: "dashboards", element: <ProtectedElement title="Dashboards" element={<Dashboards />} siteCapability="ViewSite" /> },
                            { path: "viewer", element: <ProtectedElement title="Viewer" element={<Viewer />} siteCapability="ViewSite" /> },
                            { path: "editor", element: <ProtectedElement title="Editor" element={<Editor />} siteCapability="ViewSite" /> },
                            { path: "video", element: <ProtectedElement title="Video" element={<Video />} siteCapability="ViewSite" /> },
                            { path: "tracking", element: <ProtectedElement title="Tracking" element={<Tracking />} siteCapability="ViewSite" /> },
                            { path: "siteconfig", element: <ProtectedElement title="Site Configuration" element={<Config />} siteCapability="UpdateSite" /> },
                            { path: "scans", element: <ProtectedElement title="Scans" element={<Scans />} siteCapability="ViewSite" /> },
                            { path: "areas", element: <ProtectedElement title="Areas" element={<Areas />} siteCapability="ViewSite" /> },
                            { path: "fixtures", element: <ProtectedElement title="Fixtures" element={<Fixtures />} siteCapability="ViewSite" /> },
                            { path: "hardware", element: <ProtectedElement title="Hardware" element={<Hardware />} siteCapability="UpdateSite" /> },
                            { path: "integrations", element: <ProtectedElement title="Integrations" element={<Integrations />} siteCapability="ViewSite" /> },
                            { path: "users", element: <ProtectedElement title="Site Users" element={<SiteUsers />} siteCapability="ManageSiteUsers" /> },
                            { path: "info", element: <ProtectedElement title="Site Info" element={<SiteInfo />} siteCapability="ViewSite" /> },
                            { path: "dashboard", element: <ProtectedElement title="Dashboard" element={<Dashboard />} siteCapability="ViewSite" /> },
                            { path: "heatmap", element: <ProtectedElement title="Heatmap" element={<Heatmap />} siteCapability="ViewSite" /> },
                            { path: "managevideo", element: <ProtectedElement title="Manage Video" element={<ManageVideo />} sysAdmin={true} /> }
                            ]
                        },
                        ]
                    },
                    ]
                }
                ]
            }
            ]
        }
        ]);
    
    return (<>

        <RouterProvider router={authRouter!} />
    </>);

});

export default App;
