import { useState, FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react"

import { Box, Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, Toolbar } from '@mui/material';
import { useSiteBionicsApplication } from "../models/SiteBionicsApplication";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { VideoPlayer } from "../components/VideoPlayer";
import { useSiteNavigator, useSitePageBreadcrumbs, useSite } from "./SitePage";
import DurationPicker from "../components/DurationPicker";
import { useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import TitleToolbar from "../components/TitleToolbar";
import { FragmentUploadPriority } from "../models/FragmentUploadPriority";
import ReactGA from "react-ga4";


const Video: FunctionComponent = observer(() => {
  const siteBionicsApplcation = useSiteBionicsApplication();
  const siteNavigator = useSiteNavigator();
  const breadcrumbs = useSitePageBreadcrumbs("Video");
  const [isFetchingPlayList, setIsFetchingPlayList] = useState<boolean>(false);
  const [playListUrl, setPlayListUrl] = useState<string>("");
  const [duration, setDuration] = useState<number>(1);
  const site = useSite();

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
      if (!site.hasSiteCapability("ViewSite")) return;

      siteNavigator.site.loadCamerasAsync().then(() => {
        if (searchParams.has("camera")) {
          siteNavigator.setCurrentCameraById(searchParams.get("camera")!)
        } else if (siteNavigator.site.cameras && siteNavigator.site.cameras.length > 0) {
          siteNavigator.setCurrentCameraById(siteNavigator.site.cameras[0].cameraId);
        }});

      if (searchParams.has("start") && searchParams.has("duration")) {
        let newDuration = parseInt(searchParams.get("duration")!);
        setDuration(newDuration); 
        siteNavigator.setStartTime(dayjs(searchParams.get("start")!));
        siteNavigator.setEndTime(siteNavigator.startTime!.add(duration, "minute"));
      }
  }, []);



  function runQuery(): void {
    ReactGA.event("video_play", {
      accountId: siteNavigator.site.account.accountId,
      siteId: siteNavigator.site.siteId,
      cameraId: siteNavigator.currentCamera!.cameraId,
      startTime: siteNavigator.startTime!.toISOString(),
      endTime: siteNavigator.endTime!.toISOString(),
    });

    setIsFetchingPlayList(true);
    setPlayListUrl("");
    siteBionicsApplcation.service.postUploadVideoRequest(
      siteNavigator.site.account.accountId, siteNavigator.site.siteId, siteNavigator.currentCamera!.cameraId,
      FragmentUploadPriority.UserHigh, siteNavigator.startTime!, siteNavigator.endTime!
    ).then((startTime) => {
      if (startTime) {
        const offset = siteNavigator.startTime!.diff(startTime) / 1000;
        siteBionicsApplcation.service.videoPlayListUrl(
          siteNavigator.site.account.accountId, siteNavigator.site.siteId, siteNavigator.currentCamera!.cameraId,
          siteNavigator.startTime!, siteNavigator.endTime!).then((url) => {
            // A negative offset indicates that there isn't video available at the start of the requested time range. Using
            // a negative offset works from the end of the video, not the start.
            if (offset > 0)
              url += "#t=" + offset + "," + (offset + duration * 60);
            setPlayListUrl(url);
            setIsFetchingPlayList(false);
          })
      } else {
        siteBionicsApplcation.setLastError("Video not available", "error");
        setIsFetchingPlayList(false)
      }
    });
  }

  useEffect(() => {
    setSearchParams({ 
      camera: siteNavigator.currentCamera?.cameraId ?? "",
      start: siteNavigator.startTime?.toISOString() ?? "",
      duration: duration.toString() ?? "1",
      }, { replace: true });
  }, [duration, siteNavigator.currentCamera, siteNavigator.startTime]);
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

      <BreadcrumbBar breadcrumbs={breadcrumbs} />

      <TitleToolbar>
        <>
          <FormControl sx={{marginY: "20px"}}>
            <InputLabel id="camera-list-label">Camera</InputLabel>
            <Select
              labelId="camera-list-label"
              id="camera-list"
              sx={{width: "100pt"}}
              value={siteNavigator.currentCamera?.cameraId ?? ""}
              label="Camera"            
              onChange={(event)=>{
                siteNavigator.setCurrentCameraById(event.target.value);
              }}>
              {siteNavigator.site.cameras?.map((c) => (
                <MenuItem key={c.cameraId} value={c.cameraId}>{c.cameraName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <DateTimePicker sx={{marginY: "20px"}}
            label="Start Time"
            value={siteNavigator.startTime}
            onChange={(newValue) => {
              siteNavigator.setStartTime(newValue!);
              siteNavigator.setEndTime(siteNavigator.startTime!.add(duration, "minute"));
            }}
          />
          <DurationPicker  sx={{marginY:"20px"}}
            onChange={(newDuration) => {
              setDuration(newDuration);
              siteNavigator.setEndTime(siteNavigator.startTime!.add(newDuration, "minute"));
            }}
          />
          <DateTimePicker  sx={{marginY: "20px"}}
            label="End Time"
            value={siteNavigator.endTime}
            onChange={(newValue) => {
              siteNavigator.setEndTime(newValue!);
              siteNavigator.setStartTime(siteNavigator.endTime!.subtract(duration, "minute"));
            }}
          />
          <Button id="play" sx={{marginY:"20px"}} variant="outlined" onClick={() => { runQuery() }} size="large">
            Play  
          </Button>
        </>
      </TitleToolbar>

      <Box component="div" flexGrow="1" overflow="hidden">
        {isFetchingPlayList && (
          <LinearProgress />
        )}
        {!isFetchingPlayList && playListUrl && (
          <VideoPlayer
            hlsSrc={playListUrl}
            posterSrc=""
          />
        )}
      </Box>
    </LocalizationProvider>
  )
})

export default Video;