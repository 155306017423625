
import { makeObservable, observable, action } from "mobx";
import DataContext from "./DataContext";
import Site from "./Site";
import { Color3 } from "@babylonjs/core";

export enum CameraType {
    ONVIF,
    RTSP,
    Blink
  }
  
export default class Camera {

    private dataContext: DataContext
    private draftOf?: Camera;

    @observable cameraId: string;
    id: string;
    @observable site: Site;    
    @observable cameraName: string;
    @observable cameraType: CameraType;
    @observable scanAreaId: string;
    @observable hubId: string;
    @observable address: string;
    @observable manufacturer: string;
    @observable model: string;
    @observable serialNumber: string;
    @observable onvifChannel: number;
    @observable onvifSubStream: number;
    @observable autoRtspUrl: boolean;
    @observable rtspUrl: string;
    @observable fov: number;
    @observable width: number;
    @observable height: number;
    @observable distortionCoeff: number[];
    @observable color: Color3;
    @observable videoTimeOffset: number = 0;

    constructor(dataContext: DataContext, site: Site, cameraId: string, 
            cameraName: string, cameraType: CameraType, scanAreaId: string, hubId: string, address: string,
            manufacturer: string, model: string, serialNumber: string,        
            onvifChannel: number, onvifSubStream: number, autoRtspUrl: boolean, rtspUrl: string, 
            fov: number, width: number, height: number, distortionCoeff: number[],
            color: Color3, index: number = -1) {
        this.dataContext = dataContext;
        this.site = site;
        this.id = cameraId;
        this.cameraId = cameraId;
        this.cameraName = cameraName;
        this.cameraType = cameraType;
        this.scanAreaId = scanAreaId;
        this.hubId = hubId;
        this.address = address;
        this.manufacturer = manufacturer;
        this.model = model;
        this.serialNumber = serialNumber;
        this.onvifChannel = onvifChannel;
        this.onvifSubStream = onvifSubStream;
        this.autoRtspUrl = autoRtspUrl;
        
        this.fov = fov;
        this.width = width;
        this.height = height;
        this.distortionCoeff = distortionCoeff;
        this.color = color;
        this.rtspUrl = rtspUrl;

        const hackVideoTimeOffsets = [-4,-4,-4,-4,-4,-0.11,-3.5,-0.11,-4.1];
        this.videoTimeOffset = index > hackVideoTimeOffsets.length ? 0 : hackVideoTimeOffsets[index];        

        makeObservable(this);
    }

    @action setName(cameraName: string) {
        this.cameraName = cameraName;
    }

    @action setCameraType(cameraType: CameraType) {
        this.cameraType = cameraType;
    }

    @action setScanAreaId(scanAreaId: string) {
        this.scanAreaId = scanAreaId;
    }

    @action setHubId(hubId: string) {
        this.hubId = hubId;
    }

    @action setAddress(address: string) {
        this.address = address;
    }

    @action setManufacturer(manufacturer: string) {
        this.manufacturer = manufacturer;
    }
    
    @action setModel(model: string) {
        this.model = model;
    }

    @action setWidth(width: number) {
        this.width = width;
    }

    @action setHeight(height: number) {
        this.height = height;
    }    

    @action setSerialNumber(serialNumber: string) {
        this.serialNumber = serialNumber;
    }

    @action setOnvifChannel(onvifChannel: number) {
        this.onvifChannel = onvifChannel;
    }

    @action setOnvifSubStream(onvifSubStream: number) {
        this.onvifSubStream = onvifSubStream;
    }

    @action setFov(fov: number) {
        this.fov = fov;
    }

    @action setDistortionCoeff(distortionCoeff: number[]) {
        this.distortionCoeff = distortionCoeff;
    }

    @action setColor(color: Color3) {
        this.color = color;
    }

    @action setAutoRtspUrl(autoRtspUrl: boolean) {
        this.autoRtspUrl = autoRtspUrl;
    }

    @action setRtspUrl(rtspUrl: string) {
        this.rtspUrl = rtspUrl;
    }

    @action setVideoTimeOffset(videoTimeOffset: number) {
        this.videoTimeOffset = videoTimeOffset;
    }

    clone() : Camera {
        let camera = new Camera(this.dataContext, this.site, this.cameraId, this.cameraName, this.cameraType, this.scanAreaId, this.hubId, this.address, this.manufacturer, this.model, this.serialNumber, this.onvifChannel, this.onvifSubStream, this.autoRtspUrl, this.rtspUrl, this.fov, this.width, this.height, this.distortionCoeff, this.color);
        return camera;
    }

    @action updateFrom(camera: Camera) {
        this.address = camera.address;
        this.cameraName = camera.cameraName;
        this.cameraType = camera.cameraType;
        this.fov = camera.fov;
        this.hubId = camera.hubId;
        this.manufacturer = camera.manufacturer;
        this.model = camera.model;
        this.onvifChannel = camera.onvifChannel;
        this.onvifSubStream = camera.onvifSubStream;
        this.autoRtspUrl = camera.autoRtspUrl;
        this.rtspUrl = camera.rtspUrl;
        this.scanAreaId = camera.scanAreaId;
        this.serialNumber = camera.serialNumber;
        this.color = camera.color;
        this.width = camera.width;
        this.height = camera.height;
        this.distortionCoeff = camera.distortionCoeff;
    }   
    
    async getSnapShotSasUrlAsync() : Promise<string> {
        return this.dataContext.service.fetchCameraSnapShotSasUri(this);
    }
}
