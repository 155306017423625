import { useSiteBionicsApplication } from '../models/SiteBionicsApplication';
import NoAccess from './NoAccess';
import { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';
import WithTitle from './WithTitle';

interface ProtectedElementProps {
    title: string | undefined;
    element: ReactElement;
    accountCapability?: string;
    siteCapability?: string;
    sysAdmin?: boolean;
}

const ProtectedElement: FunctionComponent<ProtectedElementProps> = observer(({ title, element, accountCapability, siteCapability, sysAdmin }) => {
    const siteBionicsApplication = useSiteBionicsApplication();

    if (sysAdmin && !siteBionicsApplication.isSystemAdministrator) {
        return <NoAccess />;
    } else if (accountCapability && !siteBionicsApplication.currentAccount?.hasAccountCapability(accountCapability)) {
        return <NoAccess title={title} account={siteBionicsApplication.currentAccount} />;
    } else if (siteCapability && !siteBionicsApplication.currentSite?.hasSiteCapability(siteCapability)) {
        return <NoAccess title={title} account={siteBionicsApplication.currentAccount} site={siteBionicsApplication.currentSite} />;
    } else {
        return <WithTitle title={title}>{element}</WithTitle>
    }
});

export default ProtectedElement;